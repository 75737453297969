<template>
    <div>
        <div id="b-1" class="nav-active-background"></div>
        <div :id="'tap'+dataBlock.id" class="bisel-active link" @mouseover="taphover(id,'hover');" @mouseout="taphover(id,'out');">
            <div class="bisel-active-left"></div>
            <div class="bisel-active-center"></div>
            <div class="bisel-active-right"></div>
        </div>
        <div class="tabs-navigation">
            <div class="tabs-content">
                <div
                    v-for="i in items"
                    :key="i.id"
                    class="tabs-item C7 link"
                    :id="i.id+dataBlock.id"
                    @click="ghactivesubtab(i.id,i.value);"
                    @mouseover="taphover(`${i.id+dataBlock.id}`,'hover');"
                    @mouseout="taphover(`${i.id+dataBlock.id}`,'out');"
                >
                    <div class="tabs-label C1 F2">{{$t(i.value)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GhSubTab",
        props:['items','dataBlock'],
        data(){
          return{
            interval:0,
            destiny:0,
            id: localStorage.getItem(this.dataBlock.id) ? localStorage.getItem(this.dataBlock.id) : this.items[0].id+this.dataBlock.id,
            data_selected: localStorage.getItem(this.dataBlock.id+'_selected') ? JSON.parse(localStorage.getItem(this.dataBlock.id+'_selected')) : this.items[0],
          }
        },
        mounted() {
              this.items.forEach((data)=>{
                 document.getElementById(data.id+this.dataBlock.id).style.width=document.getElementById(data.id+this.dataBlock.id).getBoundingClientRect().width+20+'px';
              });
              window.addEventListener('resize', this.handleresize);
              this.activedsubtab(this.id);
              document.getElementById('tap'+this.dataBlock.id).getElementsByClassName('bisel-active-center')[0].style.width=document.getElementById(this.id).offsetWidth-8+'px';
              document.getElementById('tap'+this.dataBlock.id).style.left=document.getElementById(this.id).getBoundingClientRect().left+'px';
              this.$store.dispatch(this.dataBlock.calldispatch,this.data_selected.call? {value:this.data_selected.value,call:this.data_selected.call}:{value:this.data_selected.value},{root:true});
        },
        methods: {
            handleresize(){
                document.getElementById('tap'+this.dataBlock.id).animate([{left:  document.getElementById(this.id).getBoundingClientRect().x+'px'},], {duration: 0.000000001, fill:'forwards'});
                document.querySelector('.bisel-active > .bisel-active-center').animate([{width:document.getElementById(this.id).getBoundingClientRect().width-8+'px'},], {duration: 0.000000001, fill:'forwards'});
            },
            taphover(id,state){
                if(state=='hover'){
                    document.getElementById(id).style.backgroundColor = '#CEDAE6';
                    document.getElementById(id).querySelector('.tabs-label').classList.add("F5","C6");
                    this.id == id ? '' : document.getElementById(id).querySelector('.tabs-label').classList.add("F4");
                }else  if(state == 'out'){
                    document.getElementById(id).querySelector('.tabs-label').classList.remove("F5","C6");
                    this.id == id ? '' : document.getElementById(id).querySelector('.tabs-label').classList.remove("F4");
                    document.getElementById(id).style.removeProperty('background-color');
                }
            },
            activedsubtab(id){
                document.getElementById(id).querySelector('.tabs-label').classList.add("F4");
                this.items.forEach((item) => {
                    if (id != item.id+this.dataBlock.id){
                      document.getElementById(item.id+this.dataBlock.id).querySelector('.tabs-label').classList.remove("F4");
                    }
                })
            },
            ghactivesubtab(id,value){
                localStorage.setItem(this.dataBlock.id, id+this.dataBlock.id);
                localStorage.setItem(this.dataBlock.id+'_selected',JSON.stringify(this.items[this.items.findIndex((data)=>data.id==id)]))
                this.destiny = document.getElementById( id+this.dataBlock.id).offsetLeft;
                this.id = id+this.dataBlock.id;
                this.activedsubtab(this.id);
                this.$store.dispatch(this.dataBlock.calldispatch,this.items[this.items.findIndex((data)=> data.id==id )].call? {value:value,call:this.items[this.items.findIndex((data)=> data.id==id )].call}:{value:value},{root:true});
                document.getElementById('tap'+this.dataBlock.id).animate([{left:this.destiny+'px'},], {duration: 180, fill:'forwards'});
                document.querySelector('.bisel-active > .bisel-active-center').animate([{width:document.getElementById(this.id).clientWidth-8+'px'},], {duration: 180, fill:'forwards'});
            },
        }
    }
</script>
