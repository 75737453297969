<template>
    <div>
        <label v-if="datalabel.text != '' && datalabel.text != undefined" :id="'label_date_' + datalabel.id !== undefined ? datalabel.id : ''" :class="datalabel.class != '' && datalabel.class !== undefined ? datalabel.class + ' T15_b' : 'T15_b'" :style="datalabel.style != '' && datalabel.style !== undefined ? datalabel.style + 'margin-right: 10px;' : 'margin-right: 10px;'">
            {{datainput.required ? datalabel.text + '*' : datalabel.text}}
        </label>
        <date-picker
            v-model="value1"
            :input-attr="{'id': datainput.id !== undefined ? 'input_'+datainput.id : 'input_date', 'name': datainput.name !== undefined ? datainput.name : 'input_date[date]'}"
            :format="datainput.format !== undefined ? datainput.format : 'DD/MM/YYYY'"
            type="date"
            :placeholder="datainput.placeholder !== undefined ? datainput.placeholder : 'DD/MM/YYYY'"
            :input-class="datainput.required !== undefined && datainput.required ? 'required gh_text_field-input T17 inputs' : 'gh_text_field-input T17 inputs'"
            :disabled-date="datainput.disabled_date !== undefined && datainput.disabled_date != '' ? datainput.disabled_date == 'before' ? notBeforeToday : datainput.disabled_date == 'after' ? notAfterToday : datainput.disabled_date == 'between' ? yesBetweenRange : '' : undefined"
            :popup-class="datainput.popup_class !== undefined ? datainput.popup_class : ''"
            :show-week-number="datainput.show_week_number !== undefined && datainput.show_week_number ? true : false"
            :title-format="datainput.format !== undefined ? datainput.format : 'DD/MM/YYYY'"
            @open="setWeekend"
            @calendar-change="setWeekend"
            v-on="$listeners"
        ></date-picker>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
    export default {
        name: 'GhCalendar',
        components: { DatePicker },
        props:['datalabel','datainput'],
        data() {
            return {
                value1: null,
            };
        },
        mounted() {
            var date_spliter = this.datainput.value !== undefined ? this.datainput.value.split('/') : '';
            this.value1 = this.datainput.value !== undefined ? new Date(date_spliter[2],date_spliter[1]-1,date_spliter[0]) : new Date();
        },
        methods: {
            setWeekend(){
                setTimeout(function (){
                    var elements = document.getElementsByClassName('mx-date-row');
                    Array.from(elements).forEach(function(item){
                        var itemElement = item.children;
                        Array.from(itemElement).forEach(function(item2){
                            if(item2.getAttribute('data-row-col').split(',')[1] == 5 || item2.getAttribute('data-row-col').split(',')[1] == 6){
                                item2.classList.add('weekend');
                            }
                        });
                    });
                },1);
            },
            notBeforeToday(date) {
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notAfterToday(date) {
                return date > new Date();
            },
            yesBetweenRange(date){
                const range = this.datainput.range !== undefined ? this.datainput.range : '';
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                return date < today || date > today.setDate(today.getDate() + range);
            }
        }
    };
</script>