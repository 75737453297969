<template>
    <div style="display: flex" >
        <div :style="datainput.disabled == true ? 'pointerEvents: none;opacity: 0.5;'+datainput.style : datainput.style">
            <div
                :class="datainput.type == 'checkbox' ? 'checkmark' : 'checkmark-radio'"
                @click="checkUncheck();"
                v-on="$listeners"
            >
                <div :class="datainput.type == 'checkbox' ? 'inner-checked' : 'inner_radio-checked'" style="display: none"></div>
            </div>

            <input
                :type=datainput.type
                :id=datainput.id
                :name=datainput.name
                :class="[datainput.required ? 'required' : '']"
                :value=datainput.value
                :label_required="[datalabel.text ? datalabel.text : datainput.label_required]"
                v-bind="$attrs"
                v-on="$listeners"
                style="display: none"
                :required="datainput.required ? true : false"
                :checked="datainput.selected ? true : false">
        </div>

        <label
            v-if="datalabel.text != '' && datalabel.text !== undefined"
            :class=datalabel.class
            :id=datalabel.id
            :style="datalabel.style"
            class="gh_text_field-label T15"
            style="margin-left: 10px;">
              {{datalabel.text}}
        </label>
    </div>
</template>

<script>

export default {
    name: "GhChecks",
    props:['datalabel','datainput'],
    methods:{
        checkUncheck(){
            var type = this.datainput.type;
            var name = this.datainput.name;
            if(this.$vnode.elm.children[0].children[1].checked == true){
                this.$vnode.elm.children[0].children[1].checked = false;
                if(type == 'checkbox'){
                    this.$vnode.elm.children[0].children[0].attributes[0].nodeValue = 'checkmark';
                }else{
                    this.$vnode.elm.children[0].children[0].attributes[0].nodeValue = 'checkmark-radio';
                }
                this.$vnode.elm.children[0].children[0].children[0].attributes[1].nodeValue = 'display: none;';
            }else{
              this.$vnode.elm.children[0].children[1].checked = true;
              if(type == 'checkbox'){
                  this.$vnode.elm.children[0].children[0].attributes[0].nodeValue = 'checkmark_checked';
              }else{
                // this.$vnode.elm.children[0].children[0].attributes[1].nodeValue = 'checkmark_checked-radio';
              }
              this.$vnode.elm.children[0].children[0].children[0].attributes[1].nodeValue = 'display: block;';
            }

            if(type == 'radio'){
                var radioGroup = document.getElementsByName(name);
                for(var i=0;i<radioGroup.length;i++){
                    var parent = radioGroup[i].parentNode;
                    if(radioGroup[i].checked == false){
                        parent.children[0].children[0].attributes[1].nodeValue = 'display: none;';
                    }
                }
            }
        },
        onloadRadioCheck(){
            var type = this.datainput.type;
            var checked = this.datainput.selected;

            if(type == 'checkbox'){
                if(checked){
                    this.$vnode.elm.children[0].children[0].children[0].attributes[1].nodeValue = 'display: block;';
                    this.$vnode.elm.children[0].children[0].attributes[0].nodeValue = 'checkmark_checked';
                }
            }else{
                if(checked){
                    this.$vnode.elm.children[0].children[0].children[0].attributes[1].nodeValue = 'display: block;';
                }
            }
        }
    },
    mounted(){
        this.onloadRadioCheck()
    },
}
</script>