<template>
    <transition>
        <div class="modal-backdrop" :id=generalConfiguration.id >
            <div class="modal" role="dialog" style="overflow: hidden; top: 115px; position: absolute" :style="generalConfiguration.style !== undefined ? generalConfiguration.style : ''" :class="generalConfiguration.class !== undefined ? generalConfiguration.class : ''" >
                <header>
                    <div class="modal-header">
                        <div class="T8 gh_icon_title" style="margin-right: 10px;">
                            <span v-if="generalConfiguration.type == 'add'">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:none;}</style></defs><title>181130_master_Iconos_genericos_plataformas</title><g id="Positivo_Color_negro" data-name="Positivo Color negro" style="fill: #00000000"><path style="fill: #ffffff;" class="cls-1" d="M17.42564,2.73751H4.57205A1.835,1.835,0,0,0,2.73751,4.57436V17.42564a1.835,1.835,0,0,0,1.83454,1.83685H17.42564a1.84273,1.84273,0,0,0,1.83685-1.83685V4.57436A1.84273,1.84273,0,0,0,17.42564,2.73751Zm0,14.68813H4.57205V4.57436H17.42564Z"/><polygon style="fill: #fff;" class="cls-1" points="10.08 15.591 11.917 15.591 11.917 11.917 15.589 11.917 15.589 10.083 11.917 10.083 11.917 6.409 10.08 6.409 10.08 10.083 6.409 10.083 6.409 11.917 10.08 11.917 10.08 15.591"/><path class="cls-2" d="M0,0H22V22H0Z"/></g></svg>
                            </span>
                            <span v-else-if="generalConfiguration.type == 'warning' || generalConfiguration.type == ''">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:none;}</style></defs><title>181130_master_Iconos_genericos_plataformas</title><g id="Positivo_Color_negro" data-name="Positivo Color negro" style="fill: #00000000"><path style="fill: #ffffff;" class="cls-1" d="M10.99164,14.55292a.91843.91843,0,1,0,.91734.91951A.91922.91922,0,0,0,10.99164,14.55292Z"/><path style="fill: #ffffff;" class="cls-1" d="M10.35112,9.04455h1.28337A.27449.27449,0,0,1,11.909,9.319V13.361a.27463.27463,0,0,1-.27463.27463H10.35112a.27681.27681,0,0,1-.27681-.27681V9.32136A.27681.27681,0,0,1,10.35112,9.04455Z"/><path style="fill: #ffffff;" class="cls-1" d="M19.6893,18.2367,11.43356,3.22491a.51622.51622,0,0,0-.88383,0L2.29385,18.2367a.51679.51679,0,0,0,.44192.78778H19.24739A.51738.51738,0,0,0,19.6893,18.2367ZM5.12623,17.18981,10.99164,5.96125l5.86542,11.22856Z"/><path class="cls-2" d="M-.00854,0h22V22h-22Z"/></g></svg>
                            </span>
                            <span v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:none;}</style></defs><title>181130_master_Iconos_genericos_plataformas</title><g id="Positivo_Color_negro" data-name="Positivo Color negro" style="fill: #00000000" fill="#00000000"><rect class="cls-1" x="10.08151" y="10.0826" width="1.83685" height="5.50837"/><rect class="cls-1" x="10.08151" y="6.41121" width="1.83685" height="1.83685"/><path class="cls-1" d="M11.001,1.82018a9.17982,9.17982,0,1,0,9.17989,9.17975A9.18463,9.18463,0,0,0,11.001,1.82018Zm0,16.525a7.34406,7.34406,0,1,1,7.343-7.34522A7.35536,7.35536,0,0,1,11.001,18.34515Z"/><path class="cls-2" d="M0,0H22V22H0Z"/></g></svg>
                            </span>
                        </div>
                        <div class="T8 text-inline collapsible-title">
                            {{generalConfiguration.title}}
                        </div>
                    </div>
                </header>

                <div>
                    <main class="modal-body" :id="bodyConfiguration.id !== undefined ? bodyConfiguration.id : ''" :style="bodyConfiguration.style !== undefined ? bodyConfiguration.style : ''" :class="bodyConfiguration.class !== undefined ? bodyConfiguration.class : ''" style="overflow-y: auto;">
                        <div v-if="bodyConfiguration.text !== null && bodyConfiguration.text !== undefined && bodyConfiguration.text != ''" class="T21" style="margin-top: 15px; margin-left: 20px;margin-right: 20px;">
                            {{ bodyConfiguration.text }}
                        </div>
                        <slot name="body"></slot>
                    </main>
                </div>

                <footer :id=footerConfiguration.id :style=footerConfiguration.style :class=footerConfiguration.class style="padding-bottom: 10px;">
                    <div v-if="footerConfiguration.text !== null && footerConfiguration.text !== undefined && footerConfiguration.text != ''" class="centered" style="padding: 3px; padding-bottom: 10px; padding-top: 10px;">
                        {{footerConfiguration.content}}
                    </div>
                    <div class="centered" style="display: flex;">
                        <slot name="footer"></slot>
                    </div>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "GhPopUp",
        props: ['generalConfiguration', 'bodyConfiguration', 'footerConfiguration']
    };
</script>