<template>
    <div style="display: block; min-width: 1024px;" :style="dataBlock.style !== undefined ? dataBlock.style : ''" :class="dataBlock.class !== undefined ? dataBlock.class : ''">
        <div style="display: flex;">
            <div style="display: flex;margin-top: 1px;" :id="dataBlock.id+'_block_selector'">
                <label class="T14" style="color:black !important;" :style="dataBlock.label && dataBlock.label.style?dataBlock.label.style:''"  :class="dataBlock.label && dataBlock.label.class?dataBlock.label.class:''"  :id="dataBlock.id+'_selector_label'">{{dataBlock.text}}</label>
                <div :id="dataBlock.id+'_block_selector_operators'" style="cursor: pointer;">
                    <div :id="dataBlock.id+'_children_icon_toggle'" :style="dataBlock.childicon && dataBlock.childicon.style?dataBlock.childicon.style:''"  :class="`${toggleChildrenIcon} ${dataBlock.childicon && dataBlock.childicon.class?dataBlock.childicon.class:''+' T19'}`" style=" white-space: nowrap;" @click="openSecondWindow()">{{updatesummary==true? '[ - ]' : '[ + ]'}}</div>
                </div>
            </div>
            <div class="gh_data_selector-summary T15" :id="dataBlock.id+'_selector_SUMMARY'"  style="margin-top: 1px;" >{{summaryData.length>0? summaryData:''}}</div>
            <input v-if="dataBlock.required==true" class="required" type="hidden" :label_required="dataBlock.text" :value="summaryData != '' ? 1 : 0"/>
            <div :id="`${dataBlock.id}_datasearch_select`" v-if="selectedData.length!==0">
                <input v-for="i in selectedData" :key="i.id" :name="dataBlock.nameResult+'[]'" type="hidden" :value="i.id"/>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"GhSearchWindow",
        props:['dataBlock','mainSelector','tableResolt','tableSelected'],
        data(){
            return{
                selectedData:[],
                params:'',
                updatesummary:false,
                windowselected:'',
                headersearch:new Array(),
                headerselected:new Array(),
                summaryData:[],
                data:JSON.stringify(this.tableResolt.data),
            }
        },
        computed:{
            toggleChildrenIcon() {return this.updatesummary? 'gh_data_selector-less' : 'gh_data_selector-more'},
        },
        mounted() {
          this.tableResolt.header.forEach((data)=>{this.headersearch.push(data); this.headerselected.push(data);})
          this.headersearch.findIndex((data)=>data.field=='actions')!==-1?this.tableSelected.actionsSearch==undefined || this.tableSelected.actionsSearch.length==0?this.headersearch.splice(this.headersearch.findIndex((data)=>data.field=='actions'),1):'':''
          this.headerselected.findIndex((data)=>data.field=='actions')!==-1?this.tableSelected.actionsSelected==undefined || this.tableSelected.actionsSelected.length==0?this.headerselected.splice(this.headersearch.findIndex((data)=>data.field=='actions'),1):'':''
            if (this.tableSelected && this.tableSelected.selected && this.tableSelected.selected.length!=0) {
                this.tableResolt.data.forEach((Data) => {
                    if (this.tableSelected.selected.findIndex((item) => item === Data.id) !== -1) {this.selectedData.push(Data);}
                })
                if(this.tableResolt.type!='radio') {
                    this.selectedData.forEach((Select, index) => {
                        this.selectedData[index]['check'] = true;
                        this.tableResolt.data.findIndex((item) => item.id === Select.id) !== -1 ? this.tableResolt.data[this.tableResolt.data.findIndex((item) => item.id === Select.id)]['check'] = true : ''
                    })
                }else{
                    var data=this.selectedData[0]
                    this.selectedData=new Array()
                    this.selectedData.push(data)
                    this.selectedData[0]['check'] = true;
                    this.tableResolt.data.findIndex((item) => item.id === this.selectedData.id) !== -1 ? this.tableResolt.data[this.tableResolt.data.findIndex((item) => item.id === this.selectedData.id)]['check'] = true : ''
                }
                this.setSummary();
            }
        },
        methods:{
            reloadwindow(){this.updatesummary=true;},
            closewindow(){this.updatesummary=false;},
            updateSelect(data){
                this.selectedData =data;
                this.selectedData.length!==0?this.setSummary():this.summaryData='';
            },
            openSecondWindow(){
                var then=this;
                then.windowselected.parent? then.windowselected.parent.location.reload():'';
                    this.updatesummary = true;
                    this.params = {
                        idblock:this.dataBlock.id,
                        actionsSearch:this.tableSelected.actionsSearch ? this.tableSelected.actionsSearch : '',
                        actionsSelected:this.tableSelected.actionsSelected ? this.tableSelected.actionsSelected : '',
                        actionsNoDropSelected:this.tableSelected.actionsNoDropSelected ? this.tableSelected.actionsNoDropSelected : '',
                        actionsNoDropSearch:this.tableSelected.actionsNoDropSearch ? this.tableSelected.actionsNoDropSearch : '',
                        allmarkoption:this.dataBlock.allmarkoption?this.dataBlock.allmarkoption:false,
                        textuserselected:this.dataBlock.textuserselected ? this.dataBlock.textuserselected : this.$t('Usuarios seleccionados'),
                        img:this.dataBlock.imagelocation?this.dataBlock.imagelocation:'',
                        textNofilter:this.dataBlock.textNofilter ? this.dataBlock.textNofilter : this.$t('No se ha aplicado ningún filtro'),
                        textNoResult:this.dataBlock.textNoResult ? this.dataBlock.textNoResult : this.$t('No se ha encontrado ningún resultado'),
                        nameSlot:this.mainSelector.name,
                        nameStore:this.dataBlock.nameStore,
                        ResultSearch:this.dataBlock.ResultSearch ? this.dataBlock.ResultSearch:'ResultSearch',
                        dataSelected:this.selectedData,
                        actionresult:this.dataBlock.actionresult,
                        textlocation:this.dataBlock.textlocation ? this.dataBlock.textlocation:this.$t('Añadir Usuarios'),
                        headersearch:JSON.stringify(this.headersearch),
                        headerselected:JSON.stringify(this.headerselected),
                        formId:this.mainSelector.formId,
                        actionsearch:this.mainSelector.actionsearch,
                        checkfilterselected:this.dataBlock.checkfilterselected && (this.dataBlock.checkfilterselected==1 || this.dataBlock.checkfilterselected==2) && !isNaN(Number(this.dataBlock.checkfilterselected))?this.dataBlock.checkfilterselected:1,
                        hiddencheck:this.dataBlock.hiddencheck?this.dataBlock.hiddencheck:false,
                        type:this.tableResolt.type ? this.tableResolt.type : 'checkbox',
                    }
                    var dualScreenLeft=window.screenLeft != undefined ? window.screenLeft : screen.left;
                    var dualScreenTop=window.screenTop != undefined ? window.screenTop : screen.top;
                    var width=window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                    var height=window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
                    var left=((width / 2) - (1278 / 2)) + dualScreenLeft;
                    var top=((height / 2) - (800 / 2)) + dualScreenTop;
                    var extra_css=top+',left='+left;
                    extra_css+=',resisable=no';
                    var style = this.mainSelector.style ? this.mainSelector.style : ['width='+1278,'height='+ 800,'resizable=yes','scrollbars=yes','status=yes'].join(',');
                    if(!this.mainSelector.style){if ('undefined' != typeof extra_css) {style += ',top=' + extra_css;}}
                    var windowRef =  this.Global.openSecondWindow('GhContentWindowSearchView',{idblock: this.params.idblock});
                    localStorage.setItem('Params_' + this.dataBlock.id, JSON.stringify(this.params));
                    window.UpdatedSelect = function (e) {then.updateSelect(e);}
                    window.reloadwindow=function () {then.reloadwindow();}
                    window.closewindow = function () {then.closewindow();}
                    window.events = function (e, d) {
                        d == undefined ? then.$emit(e) : '';
                        d !== undefined ? then.$emit(e, d) : '';
                    }
                    this.windowselected = window.open(windowRef.href, this.dataBlock.id + 'content_search', style);
                    window.addEventListener("beforeunload", function() {
                        then.windowselected.close();
                        localStorage.getItem('Params_' + then.dataBlock.id)?localStorage.removeItem('Params_' + then.dataBlock.id):''
                    })
            },
            setSummary(){
                var summaryKeys = Object.entries(this.tableSelected.headersummary);
                var summary='';
                let selecteddata= typeof this.selectedData=="string"?JSON.parse(this.selectedData):this.selectedData;
                if(this.tableResolt.type!='radio') {
                    selecteddata.forEach((i) => {
                        summaryKeys.forEach((key) => {
                            if (typeof key[1] == 'object') {
                                Object.entries(key[1]).forEach((subitem) => {
                                    Object.entries(subitem[1]).forEach((subitemvalue) => {
                                        if (i[subitem[0]] !== undefined) {summary += i[subitem[0]][subitemvalue[1]] + ' ';}
                                    });
                                });
                            } else {summary += i[key[1]] + ' ';}
                        })
                        summary = summary.slice(0, -1);
                        summary += ', ';
                    });
                }else{
                    selecteddata.length!=undefined?selecteddata=selecteddata[0]:''
                    summaryKeys.forEach((key) => {
                        if (typeof key[1] == 'object') {
                            Object.entries(key[1]).forEach((subitem) => {
                                Object.entries(subitem[1]).forEach((subitemvalue) => {
                                    if (selecteddata[subitem[0]] !== undefined) {summary += selecteddata[subitem[0]][subitemvalue[1]] + ' ';}
                                });
                            });
                        } else {summary +=selecteddata[key[1]] + ' ';}
                    })
                }
                summary = summary.slice(0,-2);
                this.summaryData = summary;
            }
        },
        destroyed(){localStorage.getItem('Params_'+this.dataBlock.id)?localStorage.removeItem('Params_'+this.dataBlock.id):''},
    }
</script>
